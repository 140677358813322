import { Link, LinkProps } from '@chakra-ui/layout';
import React from 'react';
import useElementClicked, {
  ElementActions,
  ElementNames,
  ElementTypes,
} from '@components/Analytics/events/ElementClicked';
interface PhoneNumberProps {
  props: LinkProps;
  children: string | JSX.Element | JSX.Element[];
}

const PhoneNumber: React.FC<PhoneNumberProps> = ({ props, children }) => {
  const elementClicked = useElementClicked();
  const phoneNumber =
    typeof props.href === 'string' ? props.href.replace('tel:', '') : '';
  const color = props.color as string;
  const textColor = props.textColor as string;
  return (
    <Link
      {...props}
      onClick={() =>
        elementClicked({
          element: {
            type: ElementTypes.TELEPHONE,
            action: ElementActions.PHONE_CALL,
            name: ElementNames.PHONE_CALL_BUTTON,
            text: phoneNumber,
            color: color,
            textColor: textColor,
          },
          destinationUrl: phoneNumber,
        })
      }
    >
      {children}
    </Link>
  );
};

export default PhoneNumber;
